import React from "react";
import { ArrowDown, UserIcon } from "../../assets/svg";
import peter from "../../assets/images/peter.png";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Sizebox from "../sizebox";
import { UsbRounded } from "@mui/icons-material";
import useAuth from "../../hooks/useAuuth";
export default function Sidebar({ routes, handleListClick }) {
  const history = useHistory();
  const path = history.location.pathname;
  const user = useAuth();
  console.log(user);
  return (
    <div className="sidebar">
      <Sizebox height="35px" />

      <div className="appLogo">
        <img src={logo} alt="" />
      </div>

      <Sizebox height="30px" />

      <div className="loginUserProfileDetails">
        <div className="avatar">
          <UserIcon />
        </div>
        <h2>
          {user?.last_name} {user?.first_name}
        </h2>
        <p>{user?.user_type}</p>
      </div>

      <ul className="mainListWrap">
        {routes.map((list) => {
          return (
            <li
              key={list?.key}
              onClick={() => {
                handleListClick(list);
              }}
            >
              {list?.Icon} <span>{list?.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
