import React from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import { Visibility } from "@mui/icons-material";
import SelectBox from "../../../../components/form/select";

const KennelsAccount = ({ handleChange, account }) => {
  return (
    <div className="petOwner">
      <div className="petOwnerDes">
        <div className="dimacation"></div>
      </div>

      <SelectBox
        placeholder="Select Vet Clinic Referee"
        options={[]}
        onChange={handleChange}
        name="clinicReferee"
        value={account?.clinicReferee}
      />
      <Sizebox height="20px" />

      <Input
        onChange={handleChange}
        name="firstName"
        value={account?.firstName}
        placeholder={"First name"}
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="lastName"
        value={account?.lastName}
        placeholder={"Last name"}
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="phoneNumber"
        value={account?.phoneNumber}
        placeholder={"Phone number"}
        type="number"
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="houseAddress"
        value={account?.houseAddress}
        placeholder={"House address"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="kennelAddress"
        value={account?.kennelAddress}
        placeholder={"Kennel address"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="email"
        value={account?.email}
        placeholder={"Email"}
        type="email"
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="password"
        value={account?.password}
        placeholder="Password"
        type="password"
        IconRight={Visibility}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="ConfirmPassword"
        value={account?.ConfirmPassword}
        placeholder="Confirm Password"
        type="password"
        IconRight={Visibility}
      />
    </div>
  );
};

export default KennelsAccount;
