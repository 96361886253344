import React, { useState, useEffect } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { axiosCalls } from "../../_api";
import { hideLoader, showLoader } from "../loader";
import { turnDate, turnUpperCase } from "../helper";

export default function ProjectCategories({ onOpenModal }) {
  const [projectCat, setProjectCat] = useState()
  const data = [];

  const getAllProjectCategories = async () => {
    showLoader();
    const res = await axiosCalls(`projects?0.owner.id=!?&owner.id=!?&r=rates.owner`, "GET");
   
    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      setProjectCat(res.data);
    }
  };

  useEffect(() => {
    getAllProjectCategories()
  }, [])

  console.log(projectCat)

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => null,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 30,
          pageSizeOptions: [50, 100, 150],
        }}
        columns={[
          // { title: "User", field: "Customer" },
          { title: "Category Title", field: "title" },
          { title: "Description", field: "description" },
          {
            title: "Created on", field: "created_at",
            render: rowData => (
              turnDate(rowData)
            )
          },
          { title: "Status", field: "status" },
          {
            title: "Actions", field: "action",
            render: rowData => (
              <span
                className="pending-action-table-btn"
                style={{
                  cursor: "pointer",
                }}
                onClick={onOpenModal}
              >
                Flag project
              </span>
            )
          },
        ]}
        data={projectCat}
        title="Projects"
      />
    </div>
  );
}
