import React from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import FileUploader from "../../../../components/form/fileUploader";
import { Visibility } from "@mui/icons-material";

const PetOwnerAccount = ({ handleChange, account }) => {
  return (
    <div className="petOwner">
      <div className="petOwnerDes">
        <div className="dimacation"></div>
        <div className="petOwnerDesCon" style={{ marginBottom: "20px" }}>
          <p>
            Only pets with microchips already installed can be registered.
            <br />
            Don’t have a microchip? <span>Start here</span>
          </p>
        </div>

        <div className="petOwnerDesCon" style={{ marginBottom: "20px" }}>
          <p>
            Registration Fee is{" "}
            <span style={{ color: "#302711", fontWeight: "600" }}>₦5,000</span>
            <br />
            <span>Why am I paying a registration fee?</span>
          </p>
        </div>
      </div>
      <Input
        onChange={handleChange}
        name="firstName"
        value={account?.firstName}
        placeholder={"First name"}
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="lastName"
        value={account?.lastName}
        placeholder={"Last name"}
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="phoneNumber"
        value={account?.phoneNumber}
        placeholder={"Phone number"}
        type="number"
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="houseAddress"
        value={account?.houseAddress}
        placeholder={"House address"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="email"
        value={account?.email}
        placeholder={"Email"}
        type="email"
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="petName"
        value={account?.petName}
        placeholder={"Pet name"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="petMicrochipNumber"
        value={account?.petMicrochipNumber}
        placeholder={"Pet’s Microchip Number"}
        type="number"
      />
      <Sizebox height="15px" />

      <FileUploader
        onChange={handleChange}
        name="petMicrochipFile"
        value={account?.petMicrochipFile}
        placeholder={"Pet’s Microchip Number"}
        type="file"
        label={"Upload Microchip Certificate"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="password"
        value={account?.password}
        placeholder="Password"
        type="password"
        IconRight={Visibility}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="ConfirmPassword"
        value={account?.ConfirmPassword}
        placeholder="Confirm Password"
        type="password"
        IconRight={Visibility}
      />
    </div>
  );
};

export default PetOwnerAccount;
