import "./index.scss";
const TablePagination = ({ control, setControl }) => {
  return (
    <div className="TablePagination">
      <div className="previous">
        <p
          onClick={() => {
            setControl({ ...control, page: control.page - 1 });
          }}
        >
          Previous
        </p>
      </div>

      <div className="count">
        <p>{control?.page}</p> <p>/</p> <p>10</p>
      </div>

      <div
        className="next"
        onClick={() => {
          setControl({ ...control, page: control.page + 1 });
        }}
      >
        <p>Next</p>
      </div>
    </div>
  );
};

export default TablePagination;
