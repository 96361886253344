import React, { useState } from "react";
import ProfileImage from "../../../components/profileImage";
import Sizebox from "../../../components/sizebox";
import Input from "../../../components/form/input";
import SelectBox from "../../../components/form/select";
import useAuth from "../../../hooks/useAuuth";
import { useEffect } from "react";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../components/loader";
import { Toast } from "../../../components/toast";

const PetOwnerProfile = () => {
  const user = useAuth();
  const [account, setAccount] = useState({ first_name: "", last_name: "" });

  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const getProfile = async () => {
    let res = null;
    let type = user?.user_type;
    showLoader();

    if (type == "PET_OWNER") {
      res = await axiosCalls("pet-owner/profile", "get");
      console.log("RES>", res, "here>>>>");
    }
    hideLoader();
    if (res && res?.status === 200) {
      setAccount({ ...account, ...res?.data });
    }
  };

  const handleSubmit = async () => {
    let requiredFields = {
      first_name: "First Name",
      last_name: "Last Name",
      address: "Address",
      middle_name: "Middle Name",
      gender: "Gender",
      phone_number: "Phone Number",
    };

    for (const field in requiredFields) {
      if (!account[field]) {
        const fieldName = requiredFields[field];
        Toast("error", `${fieldName} is required`);
        return false;
      }
    }
    const basicInfo = {
      first_name: account?.first_name,
      last_name: account?.last_name,
      phone_number: account?.phone_number,
      address: account?.address,
      middle_name: account?.middle_name,
    };
    let res = null;
    let type = user?.user_type;
    showLoader();

    if (type == "PET_OWNER") {
      res = await axiosCalls("pet-owner/profile", "PUT", basicInfo);
    }

    hideLoader();
    if (res && res?.data?.user_type) {
      setAccount({ ...account, ...res?.data });
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="" style={{ width: "100%" }}>
      <div className="pageHeaderCentered">
        <h2>Owner’s Profile</h2>
      </div>
      <Sizebox height="30px" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ProfileImage />
      </div>

      <Sizebox height="60px" />

      <div className="generalFormContainer">
        <div className="generalForm">
          <div className="generalFormChild">
            <Input
              label={"First Name"}
              value={account?.first_name}
              name="first_name"
              onChange={handleChange}
            />
          </div>

          <div className="generalFormChild">
            <Input
              label={"Email address"}
              value={account?.email}
              // name="email"
              // onChange={handleChange}
              disabled={true}
            />
          </div>

          <div className="generalFormChild">
            <Input
              label={"Last Name"}
              value={account?.last_name}
              name="last_name"
              onChange={handleChange}
            />
          </div>

          <div className="generalFormChild">
            <Input
              label={"Address"}
              value={account?.address}
              name="address"
              onChange={handleChange}
            />
          </div>

          <div className="generalFormChild">
            <Input
              label={"Middle Name"}
              value={account?.middle_name}
              name="middle_name"
              onChange={handleChange}
            />
          </div>

          <div className="generalFormChild">
            <SelectBox
              label={"Select gender"}
              value={account?.gender}
              name="gender"
              onChange={handleChange}
              options={[
                { label: "Male", value: "male", key: "male" },
                { label: "Female", value: "female", key: "Female" },
                { label: "Others", value: "others", key: "Others" },
              ]}
            />
          </div>

          <div className="generalFormChild">
            <Input
              label={"Phone number"}
              value={account?.phone_number}
              name="phone_number"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <Sizebox height="60px" />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            fontSize: "15px",
            paddingRight: "50px",
            paddingLeft: "50px",
          }}
          onClick={handleSubmit}
        >
          Update profile
        </button>
      </div>
      <Sizebox height="30px" />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #E24F6D",
            borderRadius: "5px",
            padding: "10px 30px 10px 30px",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#E24F6D" }}>Change Password</p>
        </div>
      </div>
    </div>
  );
};

export default PetOwnerProfile;
