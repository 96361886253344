import React, { useState } from "react";
import AuthWrap from "../authWrap";
import "./index.scss";
import AuthPet1 from "../../../assets/images/authPet1.png";
import PetOwnerAccount from "./petOwner";
import { useEffect } from "react";
import KennelsAccount from "./kennels";
import VetAccount from "./vetClinic";
import { userTypes } from "../../../consts";
import { hideLoader, showLoader } from "../../../components/loader";
import { Toast } from "../../../components/toast";
import { axiosCalls } from "../../../_api";
import validateEmail from "../../../components/validateEmail";
import validatePassword from "../../../components/validatePassword";
import { useHistory } from "react-router-dom";

const CreateAccount = () => {
  const history = useHistory();
  const [account, setAccount] = useState({
    accountType: userTypes.petOwner,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    email: "",
    petName: "",
    petMicrochipNumber: "",
    petMicrochipNumberCert: "",
    password: "",
    confirmPassword: "",
    CAC: "",
    veterinaryCouncilNumber: "",
    kennelAddress: "",
    vetClinicReference: "",
  });

  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const handleSubmit = async () => {
    const emailV = validateEmail(account.email);
    const passwordV = validatePassword(account.password);
    if (account?.firstName == "") {
      return Toast("error", "First name is required");
    }
    if (account?.lastName == "") {
      return Toast("error", "Last name is required");
    }

    if (account?.phoneNumber == "") {
      return Toast("error", "Phone number is required");
    }

    if (account?.houseAddress == "") {
      return Toast("error", "House address is required");
    }

    if (emailV !== true) {
      return Toast("error", "Invalid Email");
    }

    if (account?.accountType === userTypes.petOwner) {
      if (account?.petName == "") {
        return Toast("error", "Pet name is required");
      }
      if (account?.petMicrochipNumber == "") {
        return Toast("error", "Pet microchip number is required");
      }
    }

    if (account?.accountType === userTypes.vetClinic) {
      if (account?.veterinaryCouncilNumber == "") {
        return Toast("error", "Veterinary council number is required");
      }
      if (account?.CACNumber == "") {
        return Toast("error", "CAC number is required");
      }
    }

    if (account?.accountType === userTypes.kennels) {
      if (account?.kennelAddress == "") {
        return Toast("error", "kennel address is required");
      }
      if (account?.CACNumber == "") {
        return Toast("error", "CAC number is required");
      }
    }

    if (passwordV !== true) {
      return Toast("error", passwordV);
    }

    if (account?.password !== account?.ConfirmPassword) {
      return Toast("error", "Password do not match");
    }

    let data = null;
    let url = "";

    const basicInfo = {
      email: account?.email,
      password: account?.password,
      password_confirmation: account?.ConfirmPassword,
      first_name: account?.firstName,
      last_name: account?.lastName,
      phone_number: account?.phoneNumber,
      address: account?.houseAddress,
    };

    const petOwnerInfo = {
      ...basicInfo,
      pet_name: account?.petName,
      pet_microchip_number: account?.petMicrochipNumber,
    };

    const vetClinic = {
      ...basicInfo,
      veterinary_council_number: account?.veterinaryCouncilNumber,
      cac_number: account?.CACNumber,
    };

    const kennelInfo = {
      ...basicInfo,
      vet_clinic_id: null,
      kennel_address: account?.kennelAddress,
    };

    if (account?.accountType === userTypes.petOwner) {
      data = petOwnerInfo;
      url = "auth/pet-owner-register";
    }

    if (account?.accountType === userTypes.vetClinic) {
      data = vetClinic;
      url = "auth/vet-clinic-register";
    }

    if (account?.accountType === userTypes.kennels) {
      data = kennelInfo;
      url = "auth/kennel-user-register";
    }
    showLoader();
    const res = await axiosCalls(url, "POST", data);
    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }
      localStorage.setItem("token", res?.data?.access_token);
      const userInfo = {
        ...res?.data?.user,
        token: res?.data?.access_token,
        expires: res?.data?.expires_in,
      };
      console.log(userInfo);
      hideLoader();
      Toast("success", "Account created successfully");
      history.push("/");
    }
  };

  useEffect(() => {
    console.log(account);
  }, [account]);

  return (
    <AuthWrap
      petImageBottom={AuthPet1}
      onChange={handleChange}
      name="accountType"
      value={account.accountType}
      handleSubmit={handleSubmit}
    >
      {account?.accountType === userTypes.petOwner ? (
        <PetOwnerAccount handleChange={handleChange} account={account} />
      ) : null}

      {account?.accountType === userTypes.vetClinic ? (
        <VetAccount handleChange={handleChange} account={account} />
      ) : null}

      {account?.accountType === userTypes.kennels ? (
        <KennelsAccount handleChange={handleChange} account={account} />
      ) : null}
    </AuthWrap>
  );
};

export default CreateAccount;
