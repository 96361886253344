import React, { useState, useEffect } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { axiosCalls } from "../../_api";
import { hideLoader, showLoader } from "../loader";
import { turnDate, turnUpperCase } from "../helper";


export default function CategoryTable({ onOpenModal }) {
  const history = useHistory();
  const [categories, setCategories] = useState()
  const data = [];
 

  const getAllCategories = async () => {
    showLoader();
    const res = await axiosCalls(`categories?_categories.name=projects`, "GET");
   
    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      setCategories(res.data);
    }
  };

  useEffect(() => {
    getAllCategories()
  }, [])

  

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => null,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 30,
          pageSizeOptions: [50, 100, 150],
        }}
        columns={[
          { title: "Category Name", field: "name" },
          {
            title: "Created on", field: "created_at",
            render: rowData => (
              turnDate(rowData)
            )
          },
          { title: "Status", field: "status" },
          {
            title: "Actions", field: "action",
            render: rowData => (
              <div>
                <span
                  className="pending-action-table-btn"
                  style={{
                    cursor: "pointer",
                    color: "red",
                    marginRight: "20px",
                  }}
                  // onClick={onOpenModal}
                >
                  Delete category
                </span>

                <span
                  className="pending-action-table-btn"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={onOpenModal}
                >
                  Edit category
                </span>
              </div>
            )
          },
        ]}
        data={categories}
        title="Categories"
      />
    </div>
  );
}
