import React, { useState } from "react";
import Table from "./table";
import { axiosCalls } from "../../../_api";
import { useEffect } from "react";
import { hideLoader, showLoader } from "../../../components/loader";
import { Toast } from "../../../components/toast";
import { useHistory } from "react-router-dom";
import VasinationTable from "./vasinationTable";
const PetObj = {
  name: "",
  pet_microchip_number: "",
  species: "",
  date_of_birth: "",
  breed: "",
  sex: "",
  color: "",
};
const Vaccination = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("cr");
  const [data, setData] = useState([]);
  const [petInfo, setPetInfo] = useState(PetObj);
  const [meta, setMeta] = useState(null);
  const [ids, setIds] = useState([]);

  const handleClose = () => {
    setShowModal(false);
    setPetInfo();
  };

  const getPetsVers = async () => {
    showLoader();
    const res = await axiosCalls(
      `pet-owner/pets/${props?.match?.params?.id}/vaccines`,
      "get"
    );
    hideLoader();
    if (res && res?.status == 200) {
      console.log(res);
      setData(res?.data?.data);
      setMeta(res?.data);
    }
  };

  const handleEdit = (pet) => {
    let info = {
      ...pet,
      name: pet.name,
      pet_microchip_number: pet?.microchip_number,
      species: pet?.species,
      date_of_birth: pet?.date_of_birth,
      breed: pet?.breed,
      sex: pet?.sex,
      color: pet?.colour,
    };
    setPetInfo(info);
    setMode("ed");
    setShowModal(true);
    console.log(info);
  };

  const handleChange = (event) => {
    setPetInfo({ ...petInfo, [event?.target?.name]: event?.target?.value });
  };

  const handleSubmit = async () => {
    let requiredFields = {
      name: "Pet Name",
      pet_microchip_number: "Microchip Number",
      species: "species",
      date_of_birth: "Date of birth",
      breed: "breed",
      sex: "sex",
      color: "Pet Color",
    };

    for (const field in requiredFields) {
      if (!petInfo[field]) {
        const fieldName = requiredFields[field];
        Toast("error", `${fieldName} is required`);
        return false;
      }
    }
    const basicInfo = {
      name: petInfo?.name,
      microchip_number: petInfo?.pet_microchip_number,
      species: petInfo?.species,
      date_of_birth: petInfo?.date_of_birth,
      breed: petInfo?.breed,
      sex: petInfo?.sex,
      colour: petInfo?.color,
    };
    let res = null;
    showLoader();
    if (mode == "cr") {
      res = await axiosCalls("pet-owner/pets", "post", basicInfo);
    } else {
      res = await axiosCalls(
        `pet-owner/pets/${petInfo?.id}`,
        "PATCH",
        basicInfo
      );
    }
    hideLoader();
    hideLoader();
    if ((res && res?.status == 201) || res?.status == 200) {
      getPetsVers();
      setShowModal(false);
      setPetInfo(PetObj);
    } else {
      console.log(res?.er?.data?.message);
      Toast("error", res?.er?.data?.message);
    }
  };

  const handleActivationAdd = (e, id) => {
    console.log(id);
    if (ids.includes(id)) {
      let chk = ids.filter((newId, i) => {
        return id != newId;
      });
      console.log(chk);
      setIds(chk);
    } else {
      setIds([...ids, id]);
      console.log(ids);
    }
  };

  useEffect(() => {
    console.log(props?.match?.params?.id);

    getPetsVers();
  }, []);

  return (
    <div>
      <div className="dashboardMainTable">
        <div className="viewAll">
          <button
            onClick={() => {
              setMode("cr");
              setShowModal(true);
            }}
          >
            Add Pet
          </button>
        </div>
        <VasinationTable
          onOpenModal={() => {}}
          onOpenModalTracks={() => {}}
          // onOpenModalEdit={onOpenModalEdit}
          deleteRole={() => {}}
          data={data ?? []}
          reloadIsDelete={false}
          isDeleted={false}
          showModal={showModal}
          handleClose={handleClose}
          mode={mode}
          handleEdit={handleEdit}
          petInfo={petInfo}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleActivationAdd={handleActivationAdd}
          ids={ids}
        />
      </div>
    </div>
  );
};

export default Vaccination;
