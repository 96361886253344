import React, { useState, useEffect } from "react";
import Table from "../../components/table/CategoryTable";
import { Modal as ModalC } from "react-responsive-modal";
import { axiosCalls } from "../../_api";
import { NotificationManager } from "react-notifications";

const Index = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [profileId, setprofileId] = useState(null) 
  const [inputVal, setInputVal] = useState({
    name: "",
    title: "",
    description: "",
  })

  const getProfile = async () => {
    // showLoader();
    const res = await axiosCalls(`categories?q=data&name=projects`, "GET");
    setprofileId(res.data.id)
    console.log(res.data, "data");
  };
 

  const createCategory = async () => {
  let payload = {
    ...inputVal,
    _categories: [{id: profileId}]
  }
  const res = await axiosCalls(`categories`, "PUT", payload);
  NotificationManager.success("Category created successfully.", "Success");
    setInputVal({
    name: "",
    title: "",
    description: "",
  })
};

  // const assignProjectCategory = async () => {
  //   // showLoader();
  //   const res = await axiosCalls(`categories?q=data&name=profiles`, "GET");
  // };
  
  useEffect(() => {
    getProfile()
  }, [])

  return (
    <div>
      <div className="category">
        <button onClick={onOpenModal}>Create new category</button>
      </div>
      <div className="dashboardMainTable">
        {/* <div className="viewAll">
          <button>View all</button>
        </div> */}
        <Table onOpenModal={onOpenModal} />
      </div>

      <ModalC open={open} onClose={onCloseModal} center>
        <div className="successModal">
          <h2>Create category</h2>
          <p></p>

          <div className="mdInputWrap">
            <label htmlFor="">Name</label>
            <input type="text" name="name" value={inputVal.name} placeholder="Name" onChange={(e)=>setInputVal({...inputVal, name:e.target.value})} />
          </div>
          <div className="mdInputWrap">
            <label htmlFor="">Title</label>
            <input type="text" name="title" value={inputVal.title} placeholder="Title" onChange={(e)=>setInputVal({...inputVal, title: e.target.value})} />
          </div>

          <div className="mdInputWrap">
            <label htmlFor="">Description</label>
            <input type="text" name="description" value={inputVal.description} placeholder="Description" onChange={(e)=>setInputVal({...inputVal, description: e.target.value})} />
          </div>

          <div className="sendRequestBtn">
            <button onClick={createCategory}>Create</button>
          </div>
        </div>
      </ModalC>
    </div>
  );
};

export default Index;
