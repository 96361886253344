export const ArrowDown = () => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49997 6.27031C5.30283 6.27031 5.10571 6.19504 4.95541 6.04481L0.225655 1.31501C-0.0752185 1.01413 -0.0752185 0.526317 0.225655 0.225564C0.526408 -0.0751881 1.01413 -0.0751881 1.31502 0.225564L5.49997 4.41075L9.68494 0.22571C9.98581 -0.0750419 10.4735 -0.0750419 10.7742 0.22571C11.0752 0.526463 11.0752 1.01428 10.7742 1.31515L6.04453 6.04495C5.89416 6.19521 5.69704 6.27031 5.49997 6.27031Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export const LogoSvg = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="4000.000000pt"
      height="2667.000000pt"
      viewBox="0 0 4000.000000 2667.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{ height: "100px" }}
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,2667.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M19180 19640 c-25 -45 -54 -98 -65 -118 -11 -20 -23 -38 -27 -40 -4
    -2 -19 -27 -32 -57 -14 -29 -36 -66 -49 -82 -56 -66 -77 -157 -39 -169 9 -4
    62 -28 116 -55 55 -27 105 -49 112 -49 7 0 17 -6 21 -13 4 -8 28 -22 52 -33
    25 -10 63 -29 85 -41 23 -12 66 -34 96 -48 121 -59 160 -80 160 -87 0 -5 7 -8
    15 -8 14 0 105 -40 115 -50 9 -9 157 -80 168 -80 5 0 12 -7 16 -15 3 -8 11
    -15 18 -15 8 0 38 -13 68 -29 75 -39 128 -66 170 -85 19 -9 49 -25 65 -35 17
    -10 42 -21 57 -24 15 -4 47 -20 71 -36 23 -16 56 -33 72 -37 17 -3 40 -16 53
    -27 12 -11 22 -16 22 -11 0 5 7 1 14 -9 8 -10 18 -18 23 -17 14 1 183 -81 183
    -90 0 -3 29 -17 65 -30 36 -13 65 -28 65 -32 0 -4 6 -8 14 -8 8 0 21 -6 28
    -13 7 -7 40 -24 73 -37 33 -13 69 -32 79 -42 11 -10 24 -18 28 -18 9 0 222
    -104 263 -128 11 -7 40 -21 65 -32 25 -11 65 -32 90 -46 25 -14 63 -32 85 -40
    22 -8 50 -25 63 -37 13 -12 26 -21 28 -19 5 3 109 -42 134 -59 8 -5 23 -12 33
    -15 9 -4 17 -11 17 -17 0 -6 3 -8 6 -5 6 7 120 -47 128 -60 3 -5 -13 -24 -35
    -43 -23 -19 -66 -58 -97 -87 -32 -29 -72 -63 -89 -75 -40 -27 -42 -43 -6 -52
    15 -3 39 -15 54 -26 15 -10 31 -19 36 -19 15 0 44 -40 37 -51 -8 -13 -7 -770
    1 -791 5 -12 -12 -16 -97 -21 -165 -11 -148 16 -148 -231 0 -116 4 -216 8
    -221 7 -10 68 -51 107 -71 173 -92 166 -84 159 -184 -4 -47 -10 -89 -14 -95
    -9 -11 -16 -72 -28 -227 -4 -59 -11 -110 -15 -114 -4 -4 -8 -3 -8 2 -3 17 -56
    -380 -61 -451 -1 -20 -6 -39 -10 -41 -4 -3 -8 -20 -8 -37 0 -18 -7 -51 -15
    -74 -8 -23 -17 -78 -21 -123 -4 -44 -11 -83 -15 -86 -5 -3 -9 -22 -9 -42 0
    -20 -5 -55 -11 -77 -5 -22 -12 -55 -14 -74 -9 -89 -18 -137 -25 -146 -5 -5
    -14 -73 -20 -150 -7 -77 -16 -144 -21 -150 -5 -5 -9 -29 -9 -55 0 -25 -7 -72
    -15 -105 -8 -33 -15 -78 -15 -100 -1 -28 -4 -34 -9 -21 -4 11 -12 -31 -20
    -105 -7 -68 -17 -135 -21 -149 -5 -14 -12 -63 -16 -110 -3 -47 -10 -89 -14
    -95 -4 -5 -10 -32 -13 -60 -3 -27 -10 -72 -16 -100 -22 -103 -31 -163 -41
    -280 -6 -66 -15 -124 -20 -129 -6 -6 -10 -29 -10 -52 l0 -41 353 -7 c193 -4
    503 -11 687 -16 184 -5 579 -10 878 -10 l542 0 0 191 c0 134 -4 197 -12 212
    -12 21 -22 94 -47 342 -6 61 -18 142 -26 180 -26 123 -45 248 -45 301 0 28 -3
    54 -7 58 -11 11 -42 277 -59 506 -5 66 -10 96 -14 80 -5 -18 -9 -5 -14 45 -3
    39 -11 89 -16 113 -6 23 -10 62 -10 87 0 24 -4 46 -9 49 -4 3 -11 56 -15 118
    -5 75 -11 116 -20 123 -9 7 -16 49 -21 120 -4 61 -10 112 -14 115 -4 3 -13 61
    -20 130 -19 199 -23 224 -30 235 -4 6 -9 32 -10 59 -2 27 -7 60 -12 73 -5 13
    -9 65 -9 114 0 53 -5 97 -12 109 -11 18 0 32 120 149 72 71 132 133 133 137 0
    5 2 17 4 27 2 10 7 83 11 162 5 119 4 145 -7 149 -8 2 -66 9 -130 16 l-117 12
    3 377 2 376 93 95 c50 52 96 94 101 93 5 -2 9 4 9 13 0 8 -4 13 -9 10 -5 -3
    -11 0 -14 7 -2 7 -25 22 -49 35 -25 12 -62 34 -81 50 -20 15 -64 47 -98 72
    l-61 45 74 35 c40 19 90 45 110 58 21 13 65 34 99 48 34 13 71 33 81 44 10 11
    26 20 35 20 22 0 143 61 143 72 0 5 5 6 10 3 6 -3 10 -1 10 4 0 6 6 11 13 11
    14 0 726 354 746 371 7 7 46 25 85 41 39 16 80 37 91 47 11 10 43 26 70 36 28
    10 57 23 65 30 21 17 122 65 136 65 7 0 14 3 16 8 2 4 107 60 233 123 127 64
    255 130 285 146 30 17 66 33 80 38 14 4 47 21 75 38 27 18 71 40 99 49 27 10
    59 26 70 36 12 10 46 29 76 41 30 13 69 31 85 42 17 10 46 26 65 35 19 8 72
    34 118 57 103 52 105 62 26 183 -31 47 -62 102 -71 122 -8 20 -22 45 -30 54
    -16 18 -44 64 -71 118 -9 19 -23 39 -29 43 -7 4 -13 17 -13 27 0 30 -41 25
    -102 -12 -29 -18 -54 -36 -56 -40 -2 -4 -8 -8 -13 -8 -5 0 -28 -13 -51 -30
    -22 -16 -45 -30 -51 -30 -5 0 -26 -13 -46 -28 -20 -16 -62 -43 -94 -61 -31
    -18 -63 -38 -70 -46 -7 -7 -33 -23 -58 -35 -24 -13 -46 -26 -49 -30 -3 -4 -43
    -30 -90 -59 -47 -28 -114 -70 -150 -93 -127 -79 -149 -94 -237 -148 -48 -30
    -94 -60 -101 -66 -6 -7 -22 -16 -35 -20 -12 -4 -37 -20 -56 -36 -18 -16 -43
    -33 -56 -38 -12 -5 -56 -31 -98 -59 -41 -28 -80 -51 -86 -51 -6 0 -19 -8 -29
    -18 -10 -10 -59 -43 -108 -72 -49 -30 -103 -64 -120 -77 -17 -13 -34 -23 -39
    -23 -4 0 -29 -16 -56 -35 -26 -19 -71 -46 -98 -60 -27 -15 -55 -33 -62 -42 -7
    -8 -35 -26 -61 -39 -27 -14 -57 -33 -66 -42 -10 -10 -47 -33 -82 -51 -36 -18
    -73 -42 -83 -52 -10 -11 -36 -28 -59 -40 -23 -12 -66 -38 -97 -58 -53 -36 -89
    -58 -155 -98 -16 -10 -51 -32 -77 -50 -27 -17 -49 -30 -49 -27 0 3 -9 -4 -20
    -14 -12 -11 -38 -28 -58 -38 -20 -11 -53 -31 -72 -45 -34 -25 -67 -45 -133
    -80 -30 -16 -31 -16 -104 35 -41 28 -82 56 -91 61 -92 54 -118 69 -122 74 -13
    16 -79 59 -107 70 -17 7 -39 21 -49 32 -25 28 -70 25 -90 -6 -9 -14 -21 -25
    -28 -25 -6 0 -27 -15 -46 -33 -19 -17 -37 -32 -40 -31 -7 1 -74 -53 -138 -114
    -60 -55 -73 -58 -116 -21 -11 10 -52 37 -91 60 -38 24 -75 47 -81 53 -6 6 -28
    20 -49 31 -22 11 -44 24 -50 28 -29 22 -155 101 -190 119 -22 11 -43 25 -46
    30 -7 10 -20 18 -90 57 -24 13 -46 27 -49 30 -7 9 -57 41 -100 65 -19 10 -37
    21 -40 25 -9 12 -106 71 -117 71 -5 0 -27 15 -49 33 -21 18 -55 40 -74 50 -56
    28 -92 51 -112 70 -11 10 -38 26 -61 36 -23 10 -48 26 -56 34 -7 9 -25 23 -40
    31 -14 7 -43 23 -63 33 -20 11 -43 26 -52 34 -29 26 -41 34 -88 56 -27 13 -48
    27 -48 33 0 5 -27 23 -61 40 -34 16 -75 42 -92 56 -16 14 -52 36 -78 50 -27
    13 -49 28 -49 33 0 4 -30 23 -67 42 -73 37 -74 37 -119 74 -18 14 -35 25 -39
    25 -3 0 -26 14 -50 30 -24 17 -50 30 -59 30 -8 0 -19 6 -23 14 -4 8 -33 28
    -63 46 -30 18 -63 39 -72 47 -16 14 -50 35 -108 65 -14 7 -27 15 -30 19 -3 3
    -36 26 -75 50 -38 24 -71 47 -73 51 -2 4 -10 8 -18 8 -8 0 -14 5 -14 10 0 6
    -5 10 -11 10 -6 0 -23 8 -37 19 -79 55 -246 151 -265 151 -17 0 -32 -18 -67
    -80z m3516 -1831 c3 -6 19 -9 35 -7 16 3 29 2 29 -2 0 -3 19 -11 43 -18 23 -6
    67 -32 99 -57 44 -35 64 -62 93 -120 31 -65 37 -86 37 -148 0 -39 -5 -91 -13
    -115 -8 -29 -9 -46 -3 -50 6 -4 13 -15 17 -24 4 -13 17 -18 42 -18 l35 0 0
    -110 0 -110 -85 0 c-67 0 -85 3 -85 14 0 26 -19 28 -63 8 -78 -36 -77 -37 -77
    14 l0 45 -47 -8 c-27 -4 -61 -8 -78 -8 -16 0 -51 4 -77 8 l-48 8 0 -45 c0 -25
    -3 -46 -7 -46 -5 0 -35 14 -67 31 -32 17 -72 35 -88 40 -26 7 -29 6 -23 -9 22
    -53 23 -52 -66 -52 l-84 0 3 111 3 111 47 -6 47 -6 11 44 c6 28 7 53 1 68 -5
    13 -10 57 -10 98 -1 67 3 83 38 153 30 63 49 88 94 123 31 25 75 50 99 56 23
    7 42 15 42 18 0 4 13 5 29 2 16 -2 32 1 35 7 4 6 13 11 21 11 8 0 17 -5 21
    -11z m-164 -899 c3 19 4 4 2 -35 -2 -38 -10 -194 -18 -345 -7 -151 -17 -306
    -20 -345 -4 -38 -11 -196 -16 -350 -13 -399 -33 -789 -40 -795 -4 -3 -10 -66
    -13 -140 -12 -272 -47 -980 -57 -1150 -5 -96 -13 -239 -16 -317 l-7 -143 292
    0 291 0 0 520 c0 294 -4 538 -10 562 -5 24 -16 225 -25 448 -9 223 -22 540
    -30 705 -7 165 -18 485 -25 710 -6 226 -15 424 -20 441 -6 17 -10 82 -10 146
    0 90 3 117 14 121 16 6 22 -62 31 -348 13 -395 42 -869 67 -1115 5 -52 13
    -174 18 -270 4 -96 13 -215 18 -265 6 -49 13 -162 16 -250 3 -88 10 -212 15
    -275 6 -63 14 -194 18 -290 5 -116 10 -157 13 -120 3 35 8 -14 12 -130 4 -102
    12 -201 18 -220 5 -19 10 -143 10 -277 l0 -243 -427 -2 c-236 -2 -430 -2 -432
    -1 -2 2 1 47 8 100 6 53 15 153 18 222 5 80 9 113 13 91 4 -24 8 0 13 75 4 61
    16 225 27 365 11 140 20 296 20 347 0 50 4 94 8 97 5 3 14 80 21 173 6 92 15
    179 17 193 3 14 8 48 10 75 3 28 11 119 19 204 8 85 15 197 15 248 0 51 4 93
    8 93 5 0 9 6 9 13 7 322 18 533 26 539 6 4 14 96 20 227 6 122 14 221 19 221
    4 0 8 45 8 101 0 55 4 130 10 167 5 37 13 112 17 167 5 79 8 92 13 60 l6 -40
    6 35z"
        />
        <path
          d="M16320 14864 c-591 -1306 -1077 -2380 -1078 -2385 -2 -6 103 -8 278
    -7 l282 3 277 617 276 617 1289 -2 1290 -2 272 -615 273 -615 151 -3 c83 -1
    216 -1 295 0 l144 3 -1077 2380 -1078 2380 -259 2 -260 3 -1075 -2376z m1875
    498 c285 -639 515 -1165 513 -1167 -3 -3 -484 -4 -1070 -3 l-1064 3 535 1196
    536 1197 16 -32 c9 -17 249 -554 534 -1194z"
        />
        <path
          d="M4190 14840 l0 -2370 270 0 270 0 0 829 0 829 678 5 c729 6 806 10
    1041 63 370 83 646 227 876 458 210 211 324 420 387 709 30 140 33 512 5 652
    -40 194 -113 375 -214 528 -67 102 -248 280 -362 356 -238 158 -551 264 -883
    300 -61 7 -460 11 -1083 11 l-985 0 0 -2370z m2115 1842 c433 -93 717 -308
    826 -626 63 -182 75 -440 29 -625 -96 -388 -423 -664 -900 -761 -152 -31 -356
    -39 -977 -40 l-553 0 0 1041 0 1040 738 -4 c709 -3 741 -4 837 -25z"
        />
        <path
          d="M9730 14840 l0 -2370 270 0 270 0 0 915 0 915 674 0 674 0 683 -915
    683 -915 333 0 c183 0 333 2 333 5 0 3 -324 436 -719 963 -396 526 -719 958
    -717 959 2 2 57 16 122 32 223 55 394 129 572 247 315 210 507 513 563 889 18
    120 15 362 -6 480 -63 366 -257 662 -573 874 -143 96 -391 198 -583 240 -234
    50 -251 51 -1455 51 l-1124 0 0 -2370z m2383 1842 c428 -90 691 -305 789 -647
    20 -71 23 -101 22 -260 0 -167 -2 -186 -27 -268 -42 -137 -91 -225 -190 -340
    -107 -125 -311 -246 -514 -306 -213 -62 -229 -63 -1115 -68 l-808 -5 0 962 0
    961 868 -3 c858 -3 868 -4 975 -26z"
        />
        <path
          d="M26310 14840 l0 -2370 1730 0 1730 0 0 245 0 245 -1460 0 -1460 0 0
    820 0 820 1295 0 1295 0 0 250 0 250 -1295 0 -1295 0 0 805 0 805 1445 0 1445
    0 0 250 0 250 -1715 0 -1715 0 0 -2370z"
        />
        <path
          d="M31860 14840 l0 -2370 270 0 270 0 0 915 0 915 674 0 674 0 683 -915
    683 -915 333 0 c183 0 333 2 333 5 0 3 -324 436 -719 963 -396 526 -719 958
    -717 959 2 2 57 16 122 32 223 55 394 129 572 247 315 210 507 513 563 889 18
    120 15 362 -6 480 -63 366 -257 662 -573 874 -143 96 -391 198 -583 240 -234
    50 -251 51 -1455 51 l-1124 0 0 -2370z m2383 1842 c428 -90 691 -305 789 -647
    20 -71 23 -101 22 -260 0 -167 -2 -186 -27 -268 -42 -137 -91 -225 -190 -340
    -107 -125 -311 -246 -514 -306 -213 -62 -229 -63 -1115 -68 l-808 -5 0 962 0
    961 868 -3 c858 -3 868 -4 975 -26z"
        />
        <path
          d="M24265 11169 c-130 -19 -277 -76 -386 -150 -188 -126 -321 -313 -396
    -555 -26 -84 -27 -99 -27 -289 0 -190 1 -205 27 -290 127 -416 447 -674 866
    -701 283 -18 531 72 765 279 85 75 86 91 9 169 -32 32 -62 58 -68 58 -6 0 -31
    -21 -55 -46 -101 -103 -256 -197 -380 -231 -48 -13 -101 -18 -205 -18 -131 1
    -146 3 -228 32 -202 71 -347 204 -441 404 -59 124 -71 185 -71 354 1 172 13
    232 75 361 134 278 402 438 705 421 208 -12 372 -86 538 -246 l48 -46 65 68
    c87 91 86 107 -4 180 -102 84 -232 162 -329 197 -147 53 -347 72 -508 49z"
        />
        <path
          d="M16471 11068 c-26 -57 -224 -486 -440 -954 -392 -850 -393 -852 -376
    -878 16 -25 21 -26 105 -26 l87 0 118 260 118 260 524 -2 525 -3 116 -258 117
    -257 93 2 c162 5 195 -100 -315 1006 l-438 951 -93 1 -93 0 -48 -102z m354
    -667 c114 -249 204 -455 202 -457 -2 -2 -193 -3 -425 -2 l-421 3 211 463 c116
    254 215 459 219 455 5 -5 101 -212 214 -462z"
        />
        <path
          d="M12101 11138 c-4 -13 -120 -366 -257 -785 -137 -419 -252 -758 -255
    -755 -4 4 -130 355 -280 780 l-273 772 -94 0 c-79 0 -97 -3 -108 -17 -7 -10
    -13 -25 -13 -33 -1 -8 152 -438 338 -955 l339 -940 85 -3 84 -3 61 178 c46
    133 426 1259 453 1340 4 13 45 -103 340 -980 l182 -538 85 3 85 3 342 945
    c333 920 341 946 326 973 -15 26 -17 27 -106 27 l-90 0 -275 -779 c-151 -428
    -277 -777 -280 -774 -3 2 -120 354 -260 781 l-255 777 -83 3 c-78 3 -83 2 -91
    -20z"
        />
        <path
          d="M19687 11132 c-13 -14 -17 -37 -17 -89 0 -109 -19 -103 343 -103
    l307 0 0 -848 c0 -982 -13 -882 112 -882 60 0 78 4 91 18 16 17 17 91 17 865
    l0 847 308 0 c363 0 342 -6 342 107 0 55 -4 73 -18 86 -17 16 -83 17 -744 17
    -690 0 -726 -1 -741 -18z"
        />
        <path
          d="M27667 11132 c-16 -17 -17 -98 -17 -954 0 -1077 -12 -968 107 -968
    111 0 103 -34 103 453 l0 417 560 0 560 0 0 -418 c0 -488 -9 -452 107 -452 55
    0 73 4 86 18 16 17 17 98 17 954 0 1077 12 968 -107 968 -111 0 -103 33 -103
    -448 l0 -412 -560 0 -560 0 0 413 c0 482 8 447 -107 447 -55 0 -73 -4 -86 -18z"
        />
      </g>
    </svg>
  );
};

export const PetIcon = () => {
  return (
    <svg
      width="80"
      height="70"
      viewBox="0 0 80 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0002 69.2276C16.2097 69.2276 0.837023 57.6625 0.837023 39.7638C0.837023 36.5005 1.38223 31.656 2.83512 27.3514C-2.94839 18.1943 1.81095 4.82982 2.39792 3.27219C3.03545 1.57949 4.56763 0.386633 6.36507 0.182712C6.53049 0.163788 8.04341 0 10.2771 0C15.7448 0 22.8601 0.930203 27.9324 4.97762C31.815 3.85524 35.8614 3.28785 40.0002 3.28785C44.1386 3.28785 48.1848 3.85556 52.0681 4.97762C57.1396 0.930529 64.2553 0 69.7233 0C71.9567 0 73.4702 0.164441 73.6353 0.182712C75.4325 0.386633 76.9646 1.57949 77.6025 3.27219C78.1901 4.82982 82.9475 18.1936 77.1653 27.3518C78.6185 31.6573 79.1634 36.5014 79.1634 39.7642C79.1631 57.6625 63.7907 69.2276 40.0002 69.2276ZM10.5378 9.63941C9.58734 13.5661 8.86431 19.5294 11.3208 22.6828C11.4291 22.8221 11.5766 22.9859 11.7724 23.1849C13.1972 24.6326 13.5604 26.8183 12.6824 28.65C11.4043 31.3144 10.4764 35.9882 10.4764 39.7642C10.4764 45.9771 13.129 50.8685 18.3605 54.3026C23.5541 57.7115 31.2395 59.5892 39.9999 59.5892C48.7603 59.5892 56.4457 57.7115 61.6393 54.3026C66.8704 50.8685 69.5233 45.9777 69.5233 39.7642C69.5233 35.9889 68.5954 31.315 67.3174 28.6497C66.4397 26.8193 66.8032 24.6346 68.2257 23.1866C68.4248 22.9846 68.5693 22.8238 68.6806 22.6805C71.1348 19.5304 70.4127 13.571 69.461 9.64006C63.544 9.68019 59.0376 11.0825 57.0737 13.5021C55.7726 15.1051 53.5931 15.7009 51.6589 14.9847C47.9711 13.6193 44.0483 12.9273 39.9999 12.9273C35.9515 12.9273 32.0284 13.6199 28.3402 14.9847C26.4041 15.7022 24.2262 15.1051 22.925 13.5021C20.9612 11.0825 16.4551 9.67986 10.5378 9.63941Z"
        fill="#EFC356"
      />
      <path
        d="M25.9286 37.8855H25.267C22.6052 37.8855 20.4473 35.7279 20.4473 33.0658C20.4473 30.4041 22.6049 28.2461 25.267 28.2461H25.9286C28.5904 28.2461 30.7484 30.4037 30.7484 33.0658C30.748 35.7275 28.5904 37.8855 25.9286 37.8855Z"
        fill="#EFC356"
      />
      <path
        d="M54.732 37.8855H54.0697C51.4083 37.8855 49.25 35.7279 49.25 33.0658C49.25 30.4041 51.4083 28.2461 54.0697 28.2461H54.732C57.3934 28.2461 59.5517 30.4037 59.5517 33.0658C59.5514 35.7275 57.3938 37.8855 54.732 37.8855Z"
        fill="#EFC356"
      />
    </svg>
  );
};

export const UserIcon = () => {
  return (
    <svg
      width="43"
      height="54"
      viewBox="0 0 43 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.4127"
        cy="14.1569"
        r="12.9264"
        fill="#F4D78E"
        stroke="#EFC356"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.9102 45.0564C0.906727 44.1477 1.10996 43.2501 1.50452 42.4315C2.74268 39.9552 6.23429 38.6427 9.13158 38.0484C11.2211 37.6025 13.3395 37.3046 15.471 37.157C19.4172 36.8103 23.3863 36.8103 27.3325 37.157C29.4638 37.3063 31.5821 37.6042 33.6719 38.0484C36.5692 38.6427 40.0608 39.8314 41.299 42.4315C42.0924 44.1002 42.0924 46.0374 41.299 47.7061C40.0608 50.3062 36.5692 51.4949 33.6719 52.0644C31.5848 52.5288 29.4657 52.8351 27.3325 52.9806C24.1206 53.2529 20.8937 53.3026 17.6749 53.1292C16.932 53.1292 16.2139 53.1292 15.471 52.9806C13.3458 52.8369 11.2347 52.5305 9.15634 52.0644C6.23429 51.4949 2.76744 50.3062 1.50452 47.7061C1.11197 46.878 0.908944 45.9728 0.9102 45.0564Z"
        fill="#F4D78E"
        stroke="#EFC356"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UserIconSidebar = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83398 23.3333V21C5.83398 17.134 8.96799 14 12.834 14H15.1673C19.0333 14 22.1673 17.134 22.1673 21V23.3333"
        stroke="#302711"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0007 13.9998C16.578 13.9998 18.6673 11.9105 18.6673 9.33317C18.6673 6.75584 16.578 4.6665 14.0007 4.6665C11.4233 4.6665 9.33398 6.75584 9.33398 9.33317C9.33398 11.9105 11.4233 13.9998 14.0007 13.9998Z"
        stroke="#302711"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CallIconSidebar = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1378 16.1525L15.3332 17.0835C12.0878 15.4546 10.0832 13.5835 8.91649 10.6668L9.81477 5.84838L8.11676 1.3335L3.39743 1.3335C2.26988 1.3335 1.38122 2.26383 1.53639 3.38065C1.97562 6.54191 3.37006 12.7037 7.74982 17.0835C12.3467 21.6804 19.0392 23.5828 22.4655 24.2922C23.6328 24.5338 24.6665 23.6243 24.6665 22.4323L24.6665 17.8783L20.1378 16.1525Z"
        stroke="#302711"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LogoutIconSidebar = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9993 14H22.166M22.166 14L18.666 17.5M22.166 14L18.666 10.5"
        stroke="#302711"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1673 7V5.5C22.1673 4.39543 21.2719 3.5 20.1673 3.5H7.83398C6.72941 3.5 5.83398 4.39543 5.83398 5.5V22.5C5.83398 23.6046 6.72941 24.5 7.83398 24.5H20.1673C21.2719 24.5 22.1673 23.6046 22.1673 22.5V21"
        stroke="#302711"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const BellIconSidebar = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99904 4.41667C13.2207 4.41667 15.8324 7.02834 15.8324 10.25V12.8629C15.8324 13.4343 16.0421 13.9859 16.4218 14.413L17.91 16.0874C18.9132 17.2159 18.112 19 16.602 19H3.396C1.88602 19 1.08486 17.2159 2.08803 16.0874L3.57631 14.413C3.95597 13.9859 4.16569 13.4343 4.16569 12.8629L4.16571 10.25C4.16571 7.02834 6.77738 4.41667 9.99904 4.41667ZM9.99904 4.41667V1.5M8.83226 22.5H11.1656"
        stroke="#302711"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const InfoIconSidebar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
        stroke="#302711"
      />
      <path
        d="M11.076 6.34447H13.076V15.4005H11.076V6.34447ZM12.132 18.8085C11.748 18.8085 11.4333 18.7071 11.188 18.5045C10.9427 18.2911 10.82 18.0138 10.82 17.6725C10.82 17.3418 10.9427 17.0645 11.188 16.8405C11.444 16.6165 11.7587 16.5045 12.132 16.5045C12.5053 16.5045 12.8093 16.6165 13.044 16.8405C13.2893 17.0645 13.412 17.3418 13.412 17.6725C13.412 18.0138 13.2947 18.2911 13.06 18.5045C12.8253 18.7071 12.516 18.8085 12.132 18.8085Z"
        fill="black"
      />
    </svg>
  );
};

export const CreditCardIconSidebar = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4468 0.859375C22.1531 0.859375 23.5483 2.19136 23.6493 3.87227L23.6551 4.06771V13.9903C23.6551 15.6966 22.3231 17.0918 20.6422 17.1928L20.4468 17.1986H3.55599C1.8497 17.1986 0.454518 15.8667 0.353512 14.1858L0.347656 13.9903V4.06771C0.347656 2.36142 1.67964 0.966237 3.36055 0.86523L3.55599 0.859375H20.4468ZM21.9042 7.82904H2.09649L2.09766 13.9903C2.09766 14.7454 2.67151 15.3664 3.40688 15.4411L3.55599 15.4486H20.4468C21.2019 15.4486 21.8229 14.8748 21.8976 14.1394L21.9051 13.9903L21.9042 7.82904ZM19.2965 11.9185C19.7797 11.9185 20.1715 12.3103 20.1715 12.7935C20.1715 13.2365 19.8423 13.6026 19.4152 13.6606L19.2965 13.6685H16.3798C15.8966 13.6685 15.5048 13.2768 15.5048 12.7935C15.5048 12.3506 15.834 11.9845 16.2611 11.9265L16.3798 11.9185H19.2965ZM20.4468 2.60938H3.55599C2.80091 2.60938 2.17987 3.18323 2.10519 3.9186L2.09766 4.06771L2.09649 6.07904H21.9042L21.9051 4.06771C21.9051 3.31263 21.3313 2.69159 20.5959 2.6169L20.4468 2.60938Z"
        fill="#302711"
      />
    </svg>
  );
};

export const PetFootIconSidebar = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.14412 19.5415C4.86412 22.9132 7.40745 25.6665 10.7908 25.6665H16.3791C20.1824 25.6665 22.7958 22.5982 22.1658 18.8415C21.5008 14.8982 17.6974 11.6665 13.6958 11.6665C9.35579 11.6665 5.50579 15.2132 5.14412 19.5415Z"
        stroke="#302711"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2155 8.74984C13.8263 8.74984 15.1322 7.444 15.1322 5.83317C15.1322 4.22234 13.8263 2.9165 12.2155 2.9165C10.6047 2.9165 9.29883 4.22234 9.29883 5.83317C9.29883 7.444 10.6047 8.74984 12.2155 8.74984Z"
        stroke="#302711"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1829 10.1501C21.4716 10.1501 22.5163 9.1054 22.5163 7.81673C22.5163 6.52807 21.4716 5.4834 20.1829 5.4834C18.8944 5.4834 17.8496 6.52807 17.8496 7.81673C17.8496 9.1054 18.8944 10.1501 20.1829 10.1501Z"
        stroke="#302711"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.5 14.8165C25.4665 14.8165 26.25 14.0329 26.25 13.0665C26.25 12.0999 25.4665 11.3164 24.5 11.3164C23.5335 11.3164 22.75 12.0999 22.75 13.0665C22.75 14.0329 23.5335 14.8165 24.5 14.8165Z"
        stroke="#302711"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.63216 12.4831C5.92083 12.4831 6.96549 11.4384 6.96549 10.1497C6.96549 8.86107 5.92083 7.81641 4.63216 7.81641C3.3435 7.81641 2.29883 8.86107 2.29883 10.1497C2.29883 11.4384 3.3435 12.4831 4.63216 12.4831Z"
        stroke="#302711"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const NoteIconSidebar = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_155_171)">
        <path
          d="M16.3327 9.33317H5.44379C5.23751 9.33317 5.03968 9.25123 4.89382 9.10536C4.74796 8.9595 4.66602 8.76167 4.66602 8.55539V5.44428C4.66602 5.238 4.74796 5.04017 4.89382 4.89431C5.03968 4.74845 5.23751 4.6665 5.44379 4.6665H16.3327C16.539 4.6665 16.7368 4.74845 16.8827 4.89431C17.0285 5.04017 17.1105 5.238 17.1105 5.44428V8.55539C17.1105 8.76167 17.0285 8.9595 16.8827 9.10536C16.7368 9.25123 16.539 9.33317 16.3327 9.33317ZM6.22157 7.77761H15.5549V6.17539H6.22157V7.77761Z"
          fill="#302711"
        />
        <path
          d="M16.3327 10.9512H5.44379C5.23751 10.9512 5.03968 11.0331 4.89382 11.179C4.74796 11.3248 4.66602 11.5227 4.66602 11.7289V14.7778C4.66602 14.9841 4.74796 15.1819 4.89382 15.3278C5.03968 15.4737 5.23751 15.5556 5.44379 15.5556H14.2793L17.1105 12.6778V11.7289C17.1105 11.5227 17.0285 11.3248 16.8827 11.179C16.7368 11.0331 16.539 10.9512 16.3327 10.9512ZM15.5549 14.0001H6.22157V12.4445H15.5549V14.0001Z"
          fill="#302711"
        />
        <path
          d="M8.60135 24.5079V24.4612L8.85024 23.3801H3.11024V3.11122H18.6658V11.0834L20.2214 9.61344V2.33344C20.2214 2.12716 20.1394 1.92933 19.9935 1.78347C19.8477 1.63761 19.6499 1.55566 19.4436 1.55566H2.33247C2.12619 1.55566 1.92835 1.63761 1.78249 1.78347C1.63663 1.92933 1.55469 2.12716 1.55469 2.33344V24.1112C1.55469 24.3175 1.63663 24.5153 1.78249 24.6612C1.92835 24.8071 2.12619 24.889 2.33247 24.889H8.55469C8.5611 24.761 8.57669 24.6336 8.60135 24.5079Z"
          fill="#302711"
        />
        <path
          d="M17.1106 14.9102L16.5039 15.5246C16.6544 15.4936 16.7924 15.4187 16.9003 15.3093C17.0083 15.1999 17.0815 15.0611 17.1106 14.9102Z"
          fill="#302711"
        />
        <path
          d="M4.66602 20.9536C4.66602 21.1598 4.74796 21.3577 4.89382 21.5035C5.03968 21.6494 5.23751 21.7313 5.44379 21.7313H9.20824L9.44157 20.7202L9.54268 20.2924V20.2536H6.22157V18.6669H11.1527L12.7082 17.1113H5.44379C5.23751 17.1113 5.03968 17.1933 4.89382 17.3391C4.74796 17.485 4.66602 17.6828 4.66602 17.8891V20.9536Z"
          fill="#302711"
        />
        <path
          d="M26.0478 12.9657L23.4267 10.3445C23.3104 10.2279 23.1722 10.1354 23.0201 10.0722C22.8679 10.0091 22.7048 9.97656 22.5401 9.97656C22.3753 9.97656 22.2122 10.0091 22.0601 10.0722C21.9079 10.1354 21.7697 10.2279 21.6534 10.3445L10.9901 21.0701L10.1112 24.8112C10.0783 24.9726 10.0775 25.1389 10.109 25.3006C10.1405 25.4623 10.2035 25.6162 10.2946 25.7534C10.3856 25.8907 10.5028 26.0087 10.6395 26.1006C10.7762 26.1925 10.9297 26.2565 11.0912 26.289C11.1713 26.297 11.2521 26.297 11.3323 26.289C11.4276 26.3045 11.5248 26.3045 11.6201 26.289L15.3923 25.4568L26.0478 14.7779C26.1642 14.6622 26.2566 14.5247 26.3197 14.3732C26.3827 14.2217 26.4152 14.0592 26.4152 13.8951C26.4152 13.731 26.3827 13.5685 26.3197 13.417C26.2566 13.2655 26.1642 13.128 26.0478 13.0123V12.9657ZM14.599 24.0412L11.7523 24.6712L12.4445 21.8479L20.4401 13.7668L22.6334 15.9601L14.599 24.0412ZM23.5123 15.0812L21.319 12.8879L22.5556 11.6668L24.7645 13.8757L23.5123 15.0812Z"
          fill="#302711"
        />
      </g>
      <defs>
        <clipPath id="clip0_155_171">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
