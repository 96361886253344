import React from "react";
import "./index.scss";
import { UserIcon } from "../../assets/svg";
import { CameraAlt } from "@mui/icons-material";

const ProfileImage = () => {
  return (
    <div className="profileImageWrap">
      <UserIcon />

      <div className="cameraIconWrap">
        <CameraAlt />
      </div>
      <input type="file" />
    </div>
  );
};

export default ProfileImage;
