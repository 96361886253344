import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  AdminPage,
  DownloadAudio,
  HomePage,
  ShipmentPage,
  ViewShipmentPage,
  PrayerProjectCategories,
  RolesPage,
  PetOwnerProfilePage,
  PetProfileTablePage,
  VaccinationPage,
} from "./pages";
import PetOwnerAccount from "./pages/auth/createAccount/petOwner";
import CreateAccount from "./pages/auth/createAccount/createAccount";
import LoginPage from "./pages/auth/login/login";
import ResetPasswordPage from "./pages/auth/resetPass/reset";
import { userTypes } from "./consts";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/create_account" component={CreateAccount} />
        <Route
          exact
          path="/auth_reset_password"
          component={ResetPasswordPage}
        />

        {/* PET OWNER PROFILE ROUTES  */}

        <Route exact path={`/profile`} component={PetOwnerProfilePage} />
        <Route exact path={`/pet_profile`} component={PetProfileTablePage} />
        <Route exact path={"/vaccination/:id"} component={VaccinationPage} />

        {/* PET OWNER PROFILE ROUTES END */}

        <Route exact path="/dashboard" component={HomePage} />
        <Route exact path="/projects" component={ShipmentPage} />
        <Route exact path="/roles" component={RolesPage} />
        <Route
          exact
          path="/prayer_project_categories"
          component={PrayerProjectCategories}
        />
        <Route exact path="/media" component={AdminPage} />
        <Route exact path="/view-user/:id" component={ViewShipmentPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/audio/:link" component={DownloadAudio} />
      </Switch>
    </Router>
  );
}
