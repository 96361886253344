import React, { useState, useEffect } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Modal as ModalC } from "react-responsive-modal";
import { Paper } from "@material-ui/core";
import { hideLoader, showLoader } from "../../components/loader";
import { turnDate, turnUpperCase } from "../helper";
import { axiosCalls } from "../../_api";
import { NotificationManager } from "react-notifications";

export default function BooksTable({labels, musicVal, open, setMusicVal, deleteLabel, onCloseModal, onOpenModal, onOpenModalTracks }) {
  const [backgound, setBackground] = useState()
  // const [labels, setLabels] = useState()

  // const data = [];

  // const getAllLabels = async () => {
  //   showLoader();
  //   const res = await axiosCalls(`categories?_categories.name=background_audios`, "GET");
   
  //   if (res) {
  //     hideLoader();
  //     if (res.er) {
  //       return;
  //     }
  //     setLabels(res.data);
  //   }
  // };

  // console.log(labels)

  // useEffect(() => {
  //   getAllLabels()
  // }, [])

  const handleAddBackground = (data) => {
    console.log(data, "dt")
    setBackground(data)
    onOpenModal()

  }

  const addBackgroundMusic = async (rowData) => {
    console.log(rowData.id)
  let payload = {
    ...musicVal,
    categories: [{id: rowData.id}]
  }
    const res = await axiosCalls(`files`, "PUT", payload);
    if (res) {
      NotificationManager.success("Added background music successfully.", "Success");
        setMusicVal({
        title: "",
        value: "",
        })
      
    }
    
  };

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [50, 100, 150],
        }}
        columns={[
          { title: "Name", field: "name" },
          { title: "Title", field: "title" },
          { title: "Description", field: "description" },
          {
            title: "Date added", field: "created_at",
            render: rowData => (
              turnDate(rowData)
            )
          },
          { title: "Status", field: "status" },
          {
            title: "Actions", field: "action",
            render: rowData => (
              <div>
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "green",
                  }}
                  className="pending-action-table-btn"
                  onClick={onOpenModalTracks}
                >
                  View tracks
                </span>

                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "red",
                  }}
                  className="pending-action-table-btn"
                  onClick={() => deleteLabel(rowData)}
                >
                  Delete Label
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  className="pending-action-table-btn"
                  onClick={() => handleAddBackground(rowData)}
                >
                  Add background music
                </span>
              </div>
            )
          },
        ]}
        data={labels}
        title="All Labels"
      />

       <ModalC open={open} onClose={onCloseModal} center>
        <div className="successModal">
          <h2>Add song to label</h2>

          <div className="mdInputWrap">
            <label htmlFor="">Title</label>
            <input type="text" name="title" value={musicVal.title} placeholder="Title" onChange={(e)=>setMusicVal({...musicVal, title: e.target.value})} />
          </div>

          <div className="mdInputWrap">
            <label htmlFor="">Mp3 file</label>
            <input type="file" value={musicVal.value} placeholder="Audio link" onChange={(e)=>setMusicVal({...musicVal, value: e.target.value})} />
          </div>

          <div className="sendRequestBtn">
            <button onClick={() => addBackgroundMusic(backgound.id)}>Create</button>
          </div>
        </div>
      </ModalC>
    </div>
  );
}
