import React from "react";

const Sizebox = ({
  width = "0px",
  height = "0px",
  backgroundColor = "transparent",
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: backgroundColor,
      }}
    ></div>
  );
};

export default Sizebox;
