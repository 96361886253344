import React from "react";
import "./index.scss";
const Input = ({
  label,
  placeholder,
  onChange,
  name = "Select",
  value,
  type = "text",
  IconRight = null,
  IconRightClick = () => {},
  IconLeft = null,
  IconLeftClick = () => {},
  disabled = false,
}) => {
  return (
    <div className="appInput">
      {label ? <label htmlFor="">{label}</label> : null}
      <input
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        type={type}
        style={IconLeft ? { paddingLeft: "35px" } : {}}
        disabled={disabled}
      />
      {IconRight ? (
        <div className="iconRight">
          <IconRight onClick={IconRightClick} />
        </div>
      ) : null}

      {IconLeft ? (
        <div className="iconLeft">
          <IconLeft onClick={IconLeftClick} />
        </div>
      ) : null}
    </div>
  );
};

export default Input;
