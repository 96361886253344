import React, { useState } from "react";
import AuthWrap from "../authWrap";
import "./index.scss";
import AuthPet1 from "../../../assets/images/authPet1.png";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ResetPasswordForm from "./reset/index";

const ResetPasswordPage = () => {
  const history = useHistory();
  const [account, setAccount] = useState({
    accountType: "",
    firstName: "",
  });

  const [tab, setTab] = useState(1);

  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const handleSubmit = () => {
    if (tab === 1) {
      setTab(2);
    }
  };

  useEffect(() => {
    console.log(account);
  }, [account]);

  return (
    <AuthWrap
      petImageBottom={AuthPet1}
      onChange={handleChange}
      name="accountType"
      value={account.accountType}
      handleSubmit={handleSubmit}
      header="Forgot Password?"
      subHeader="Input your email address & we’ll send you reset instructions"
      subBtnText={"Continue"}
      type="login"
      showDrop={false}
    >
      <ResetPasswordForm
        handleChange={handleChange}
        account={account}
        history={history}
        tab={tab}
        setTab={setTab}
      />
    </AuthWrap>
  );
};

export default ResetPasswordPage;
