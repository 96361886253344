import React, { useState, useEffect } from "react";
import Cards from "./cards";
import Table from "../../components/table/tableDashboard";
import TopUsers from "../../components/table/topCust";
import "./index.scss";
import { useHistory } from "react-router-dom";
import TablePagination from "../../components/tablePagination/pagination";
import { axiosCalls } from "../../_api";
import { hideLoader, showLoader } from "../../components/loader";

const pdata = [
  {
    name: "Jan",
    DropOffs: 11,
    PickUps: 120,
  },
  {
    name: "Feb",
    DropOffs: 15,
    PickUps: 12,
  },
  {
    name: "Mar",
    DropOffs: 55,
    PickUps: 10,
  },
  {
    name: "Apr",
    DropOffs: 10,
    PickUps: 5,
  },
  {
    name: "May",
    DropOffs: 9,
    PickUps: 4,
  },
];

export default function HomePage() {
  const history = useHistory();
  const [card, setCards] = useState([
    {
      name: "Total Users",
      value: "2378",
    },

    {
      name: "Total Donation",
      value: "$6378",
    },

    {
      name: "Total Prayers",
      value: "6378",
    },
  ]);

  const [users, setUsers] = useState([]);
  const [control, setControl] = useState({ page: 1 });

  useEffect(() => {
    // getUsers();
  }, [control.page]);

  const getUsers = async () => {
    showLoader();
    const res = await axiosCalls(`community?page=${control.page}`, "GET");
    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      setUsers(res.data);
      setControl(res);
    }
    console.log(res);
  };
  return (
    <div>
      {/* <div className="cardWrap">
        {card.map((data) => {
          return <Cards data={data} />;
        })}
      </div>

      <div className="dashboardMainTable">
        <Table data={users} />
        <TablePagination control={control} setControl={setControl} />
      </div> */}
    </div>
  );
}
