import React from "react";
import "./index.scss";
const SelectBox = ({
  options = [],
  placeholder = "Please Select",
  label,
  onChange,
  name = "Select",
  value,
}) => {
  return (
    <div className="appInput">
      {label ? <label htmlFor="">{label}</label> : null}
      <select name={name ?? ""} onChange={onChange} value={value}>
        <option value="">{placeholder}</option>
        {options.map((option) => {
          return (
            <option key={option?.key} value={option?.value}>
              {option?.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectBox;
