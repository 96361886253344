import React, { useState, useEffect } from "react";
import Table from "../../components/table/roles";
import { Modal as ModalC } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { NotificationManager } from "react-notifications";
import { axiosCalls } from "../../_api";
import { hideLoader, showLoader } from "../../components/loader";

const Index = () => {
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState();
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [openLabel, setOpenLabel] = useState(false);
  const onOpenModalLabel = () => setOpenLabel(true);
  const onCloseModalLabel = () => setOpenLabel(false);

  const [openTracks, setOpenTracks] = useState(false);
  const onOpenModalTracks = () => setOpenTracks(true);
  const onCloseModalTracks = () => setOpenTracks(false);
  const [profileId, setprofileId] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const history = useHistory();

  const [inputVal, setInputVal] = useState({
    name: "",
    title: "",
    description: "",
  });

  const reloadIsDelete = () => {
    setIsDeleted(!isDeleted);
  };

  const getAllRoles = async () => {
    showLoader();
    const res = await axiosCalls(`categories?_categories.name=profiles`, "GET");

    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      setRoles(res.data);
    }
  };

  const getProfile = async () => {
    // showLoader();
    const res = await axiosCalls(`categories?q=data&name=profiles`, "GET");
    setprofileId(res.data.id);
    // the profile id is 1 and for that reason I can't create another role.
    console.log(res.data, "data");
  };

  const createRole = async () => {
    console.log(profileId, "profileId");
    let payload = {
      ...inputVal,
      _categories: [{ id: profileId }],
      // there is a duplicate error when trying to create a new role. Align with Victor
    };
    showLoader();
    const res = await axiosCalls(`categories`, "PUT", payload);

    if (res) {
      NotificationManager.success("Role created successfully.", "Success");
      setInputVal({
        name: "",
        title: "",
        description: "",
      });
      hideLoader();
      setIsSaved(true);
    }
  };

  const deleteRole = async (oldData) => {
    showLoader();
    const res = await axiosCalls(`categories/${oldData.id}`, "DELETE");
    if (res) {
      const dataDelete = [...roles];
      console.log(dataDelete, "dataDelete");
      const index = oldData.tableData.id;
      console.log(index, "index");
      dataDelete.splice(index, 1);
      console.log(dataDelete.splice(index, 1), "splic");
      setRoles([...dataDelete]);
      NotificationManager.success(res.title, "Success");
      hideLoader();
    }
  };

  useEffect(() => {
    getAllRoles();
    getProfile();
  }, [isSaved, isDeleted]);

  const handleReques = () => {
    onCloseModal();
    NotificationManager.success("Request sent successfully.", "Success");
  };

  return (
    <div>
      <div className="dashboardMainTable">
        <div className="viewAll">
          <button onClick={onOpenModalLabel}>Create Role</button>
        </div>
        <Table
          onOpenModal={onOpenModalLabel}
          onOpenModalTracks={onOpenModalTracks}
          // onOpenModalEdit={onOpenModalEdit}
          deleteRole={deleteRole}
          roles={roles}
          reloadIsDelete={reloadIsDelete}
          isDeleted={isDeleted}
        />
      </div>

      <ModalC open={open} onClose={onCloseModal} center>
        <div className="successModal">
          <h2>Add song to label</h2>

          <div className="mdInputWrap">
            <label htmlFor="">Mp3 file</label>
            <input type="file" />
          </div>

          <div className="sendRequestBtn">
            <button onClick={handleReques}>Create</button>
          </div>
        </div>
      </ModalC>

      <ModalC open={openLabel} onClose={onCloseModalLabel} center>
        <div className="successModal">
          <h2>Create Role</h2>

          <div className="mdInputWrap">
            <label htmlFor="">Name</label>
            <input
              type="text"
              name="name"
              value={inputVal.name}
              placeholder="Name"
              onChange={(e) =>
                setInputVal({ ...inputVal, name: e.target.value })
              }
            />
          </div>
          <div className="mdInputWrap">
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              value={inputVal.title}
              placeholder="Title"
              onChange={(e) =>
                setInputVal({ ...inputVal, title: e.target.value })
              }
            />
          </div>

          <div className="mdInputWrap">
            <label htmlFor="">Description</label>
            <input
              type="text"
              name="description"
              value={inputVal.description}
              placeholder="Description"
              onChange={(e) =>
                setInputVal({ ...inputVal, description: e.target.value })
              }
            />
          </div>

          <div className="sendRequestBtn">
            <button onClick={createRole}>Create</button>
          </div>
        </div>
      </ModalC>

      <ModalC open={openTracks} onClose={onCloseModalTracks} center>
        <div className="successModal">
          <h2>Tracks</h2>

          <div
            className="mdInputWrap"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <audio src="" controls></audio>
            <span
              style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
            >
              Delete
            </span>
          </div>
        </div>
      </ModalC>
    </div>
  );
};

export default Index;
