import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import "./index.scss";
import { PersonOutlined, NoteAdd } from "@mui/icons-material";
import { useEffect } from "react";
import { userTypes } from "../../consts";
import { PetsOutlined } from "@material-ui/icons";
import {
  BellIconSidebar,
  CallIconSidebar,
  CreditCardIconSidebar,
  InfoIconSidebar,
  LogoutIconSidebar,
  NoteIconSidebar,
  PetFootIconSidebar,
  UserIcon,
  UserIconSidebar,
} from "../../assets/svg";
// import EditNoteIcon from "@mui/icons-material/EditNote";

export const ProtectedLayout = ({ children }) => {
  const history = useHistory();
  const [auth, setAuth] = useState(true);
  const [activeUser, setActiveUser] = useState("petOwner");
  const [routes, setRoutes] = useState([]);

  const [petOwnerRoutes, setPetOwnerRoutes] = useState([
    {
      name: "Owner’s Profile",
      route: `/profile`,
      Icon: <UserIconSidebar />,
    },

    {
      name: "Pet’s Profile",
      route: `/pet_profile`,
      Icon: <PetFootIconSidebar />,
    },

    {
      name: "Vaccination Details",
      route: `/${userTypes?.petOwner}/vaccination_details`,
      Icon: <NoteIconSidebar />,
    },

    {
      name: "Annual Dues",
      route: `/${userTypes?.petOwner}/annual_dues`,
      Icon: <CreditCardIconSidebar />,
    },

    {
      name: "Notifications",
      route: `/notifications`,
      Icon: <BellIconSidebar />,
    },

    {
      name: "Contact Us",
      route: `/contact_us`,
      Icon: <CallIconSidebar />,
    },

    {
      name: "Report Missing Pet",
      route: `/report_missing_pet`,
      Icon: <InfoIconSidebar />,
    },

    {
      name: "Logout",
      route: `/${userTypes?.petOwner}/vaccination_details`,
      Icon: <LogoutIconSidebar />,
    },
  ]);

  const handleListClick = (list) => {
    history.push(list?.route);
  };

  useEffect(() => {
    if (activeUser === userTypes.petOwner) {
      setRoutes(petOwnerRoutes);
    }
  }, [activeUser]);

  return (
    <div className="layout">
      <div className="layout__sidebar">
        <Sidebar routes={routes} handleListClick={handleListClick} />
      </div>

      <div className="children-wrap">
        <div className="layout__body ">
          <div className="layout__content">
            {auth ? children : history.push(`/login`)}
          </div>
        </div>
      </div>
    </div>
  );
};
