import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import auth from "./auth.js";

// const saveSubsetBlacklistFilter = createBlacklistFilter('auth', ['isAuthenticated']);

const reducers = combineReducers({
  auth,
});

const persistConfig = {
  key: "root",
  storage,
  // transforms: [saveSubsetBlacklistFilter]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
