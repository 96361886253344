import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.jpeg";
import "./index.scss";
import i1 from "../../assets/images/ios.png";
import i2 from "../../assets/images/gg.png";
import { LogoSvg } from "../../assets/svg";
import { axiosCalls } from "../../_api";
import axios from "axios";

export default function DownloadAudiopAGE() {
  const history = useHistory();
  const params = useParams();
  const [file, setFile] = React.useState(null);

  useEffect(() => {
    getAudio();
    // downloadVideo();
  }, []);

  const downloadVideo = (url) => {
    axios({
      url: file?.value,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", `${file.title}.mp3`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const loginF = () => {
    history.push(`/dashboard`);
    NotificationManager.success("Login successfull", "Welcom admin");
  };

  const getAudio = async () => {
    const res = await axiosCalls(`files/${params?.link}`, "get");
    console.log(res);
    setFile(res?.data);
  };
  return (
    <div className="auth">
      <div className="inputWrapauth">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <LogoSvg />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <h3>Record made using Prayer watch mobile app</h3>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <img
              src={i2}
              alt=""
              style={{
                // height: "100px",
                width: "100px",
                marginRight: "40px",
                cursor: "pointer",
              }}
            />
            <img
              src={i1}
              alt=""
              style={{
                width: "100px",
                cursor: "pointer",
              }}
            />
          </div>
          {/* <h2>{file?.title}</h2> */}
          {file == null ? null : (
            <audio
              src={file?.value}
              controls
              style={{
                marginTop: "20px",
              }}
            ></audio>
          )}
        </div>

        {file == null ? null : (
          <div className="btnAuth">
            <button onClick={downloadVideo}>Download Prayer</button>
          </div>
        )}
      </div>
    </div>
  );
}
