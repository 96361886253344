import React, { useState, useEffect } from "react";
import Table from "../../components/table/admins";
import { Modal as ModalC } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { axiosCalls } from "../../_api";
import { NotificationManager } from "react-notifications";
import { hideLoader, showLoader } from "../../components/loader";

const Index = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [openLabel, setOpenLabel] = useState(false);
  const onOpenModalLabel = () => setOpenLabel(true);
  const onCloseModalLabel = () => setOpenLabel(false);

  const [openTracks, setOpenTracks] = useState(false);
  const onOpenModalTracks = () => setOpenTracks(true);
  const onCloseModalTracks = () => setOpenTracks(false);
  const [audioId, setAudioId] = useState(null) 
  const [songId, setSongId] = useState(null)
  const [labels, setLabels] = useState()
  const history = useHistory();


  const [inputVal, setInputVal] = useState({
    name: "",
    title: "",
    description: "",
  })

   const [musicVal, setMusicVal] = useState({
     title: "",
    value: "",
   })
  
  const getAllLabels = async () => {
    showLoader();
    const res = await axiosCalls(`categories?_categories.name=background_audios`, "GET");
   
    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      setLabels(res.data);
    }
  };

  const getAudio = async () => {
    // showLoader();
    const res = await axiosCalls(`categories?q=data&name=background_audios`, "GET");
    setAudioId(res.data.id)
    console.log(res.data, "audio");
  };

  const createLabel = async () => {
    console.log(audioId, "audio2");
  let payload = {
    ...inputVal,
    _categories: [{id: audioId}]
  }
    const res = await axiosCalls(`categories`, "PUT", payload);
    if (res) {
      setSongId(res.id)
      NotificationManager.success("Label created successfully.", "Success");
        
      setInputVal({
        name: "",
        title: "",
        description: "",
        })
      
    } else {
      NotificationManager.error(res.message, "Error");
    }
  };
  
  

  
  const deleteLabel = async (oldData) => {
    console.log(oldData.id)
    showLoader();
    const res = await axiosCalls(`categories/${oldData.id}`, "DELETE");
    console.log(res)
    if (res) {
      const dataDelete = [...labels];
      console.log(dataDelete, "dataDelete")
      const index = oldData.tableData.id;
      console.log(index, "index")
      dataDelete.splice(index, 1);
      console.log(dataDelete.splice(index, 1), "splic")
      setLabels([...dataDelete]);
      NotificationManager.success(res.title, "Success");
      hideLoader();
      
      
    }
  };





  const handleReques = () => {
    onCloseModal();
    NotificationManager.success("Request sent successfully.", "Success");
  };

  useEffect(() => {
  getAllLabels()
    getAudio()
  }, [])

  return (
    <div>
      <div className="dashboardMainTable">
        <div className="viewAll">
          <button onClick={onOpenModalLabel}>Create Label</button>
        </div>
        <Table
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          onOpenModalTracks={onOpenModalTracks}
          labels={labels}
          deleteLabel={deleteLabel}
          musicVal={musicVal}
          setMusicVal={setMusicVal}
          // addBackgroundMusic={addBackgroundMusic}
          open={open}
        />
      </div>

     

      <ModalC open={openLabel} onClose={onCloseModalLabel} center>
        <div className="successModal">
          <h2>Create label</h2>

          <div className="mdInputWrap">
            <label htmlFor="">Name</label>
            <input type="text" name="name" value={inputVal.name} placeholder="Name" onChange={(e)=>setInputVal({...inputVal, name:e.target.value})} />
          </div>
          <div className="mdInputWrap">
            <label htmlFor="">Title</label>
            <input type="text" name="title" value={inputVal.title} placeholder="Title" onChange={(e)=>setInputVal({...inputVal, title: e.target.value})} />
          </div>

          <div className="mdInputWrap">
            <label htmlFor="">Description</label>
            <input type="text" name="description" value={inputVal.description} placeholder="Description" onChange={(e)=>setInputVal({...inputVal, description: e.target.value})} />
          </div>

          <div className="sendRequestBtn">
            <button onClick={createLabel}>Create</button>
          </div>
        </div>
      </ModalC>

      <ModalC open={openTracks} onClose={onCloseModalTracks} center>
        <div className="successModal">
          <h2>Tracks</h2>

          <div
            className="mdInputWrap"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <audio src="" controls></audio>
            <span
              style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
            >
              Delete
            </span>
          </div>
        </div>
      </ModalC>
    </div>
  );
};

export default Index;
