import React, { useState } from "react";
import "./index.scss";
import { Modal as ModalC } from "react-responsive-modal";
import userImg from "../../assets/images/peter.png";
import { hideLoader, showLoader } from "../../components/loader";
import { axiosCalls } from "../../_api";

export default function SingleShip(props) {
  const [users, setUsers] = useState([]);
  let id = props?.match?.params?.id;
  console.log(id);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  React.useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    showLoader();
    const res = await axiosCalls(`community/${id}`, "GET");
    if (res) {
      hideLoader();
      if (res.er) {
        return;
      }
      setUsers(res.data);
    }
    console.log(res);
  };

  return (
    <div>
      <h2 className="shipmentHead">User Summary</h2>

      <div className="summaryBody">
        <div className="col1">
          <img
            src={userImg}
            style={{ width: "70px", height: "70px", borderRadius: "50%" }}
            alt=""
          />
          <h4 className="usersumship">{users?.full_name}</h4>
          <p className="timerSvg">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 0C4.26179 0 0 4.26179 0 9.5C0 14.7382 4.26179 19 9.5 19C14.7382 19 19 14.7382 19 9.5C19 4.26179 14.7382 0 9.5 0ZM9.5 17.8125C4.91641 17.8125 1.18751 14.0836 1.18751 9.5C1.18751 4.91641 4.91641 1.18751 9.5 1.18751C14.0836 1.18751 17.8125 4.91641 17.8125 9.5C17.8125 14.0836 14.0836 17.8125 9.5 17.8125Z"
                fill="#878787"
              />
              <path
                d="M10.0938 3.5625H8.90625V9.74583L12.6427 13.4823L13.4823 12.6427L10.0938 9.25413V3.5625Z"
                fill="#878787"
              />
            </svg>
            <span> Thu, 6th Feb., 2022 - 09:00AM - In 21 days</span>
          </p>

          <div className="fromwrap">
            <div>
              <h2>Email</h2>
              <p>{users?.email}</p>
            </div>

            <div>
              <h2>Total Project</h2>
              <p>----</p>
            </div>

            <div>
              <h2>Role</h2>
              <p>----</p>
            </div>
          </div>
        </div>
        <div className="col2">
          <h2 className="header">Actions</h2>

          <a href={`mailto:${users?.email}`} target="_blank" rel="noreferrer">
            <button>Email User</button>
          </a>

          <button style={{ backgroundColor: "red" }} onClick={onOpenModal}>
            Block User
          </button>
        </div>
      </div>

      <ModalC open={open} onClose={onCloseModal} center>
        <div className="successModal">
          <h2>Reason why account account was disabled </h2>

          <div className="mdInputWrap">
            <label htmlFor="">Reason and instruction</label>
            <textarea type="text" placeholder="..." />
          </div>

          <div className="sendRequestBtn">
            <button onClick={onCloseModal}>Send</button>
          </div>
        </div>
      </ModalC>
    </div>
  );
}
