import React, { useState } from "react";
import Table from "../../components/table/ProjectCategories";
import { Modal as ModalC } from "react-responsive-modal";

const Index = (props) => {
  console.log(props);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div>
      <div className="category">
        <button
          onClick={() => {
            props.history.push("/prayer_project_categories");
          }}
        >
          Prayer project categories
        </button>
      </div>
      <div className="dashboardMainTable">
        {/* <div className="viewAll">
          <button>View all</button>
        </div> */}
        <Table onOpenModal={onOpenModal} />
      </div>

      <ModalC open={open} onClose={onCloseModal} center>
        <div className="successModal">
          <h2>Flag public prayer</h2>
          <p>
            This will make this project private to the user, please specify why
            this project was made private
          </p>

          <div className="mdInputWrap">
            <label htmlFor="">Reason and instruction</label>
            <textarea type="text" placeholder="..." />
          </div>

          <div className="sendRequestBtn">
            <button onClick={onCloseModal}>Send</button>
          </div>
        </div>
      </ModalC>
    </div>
  );
};

export default Index;
