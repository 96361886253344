import React from "react";
import { ProtectedLayout } from "./components/layout/index";
import HomePageRoute from "./pages/home/index.jsx";
import ShipmentRoute from "./pages/projectcategories/index";
import Category from "./pages/categories/categories";
import AdminRoute from "./pages/admins/index";
import Login from "./pages/auth/login";
import ViewShipment from "./pages/projectcategories/singleShip";
import DownloadAudiopAGE from "./pages/public";
import RoleRoute from "./pages/roles/index";
import PetOwnerProfile from "./pages/petOwnerPages/ownerProfile/index.jsx";
import PetProfileTable from "./pages/petOwnerPages/petProfile/index.jsx";
import Vaccination from "./pages/petOwnerPages/petProfile/versination.jsx";

export const HomePage = (props) => {
  return (
    <ProtectedLayout>
      <HomePageRoute {...props} />
    </ProtectedLayout>
  );
};

export const PetOwnerProfilePage = (props) => {
  return (
    <ProtectedLayout>
      <PetOwnerProfile {...props} />
    </ProtectedLayout>
  );
};

export const PetProfileTablePage = (props) => {
  return (
    <ProtectedLayout>
      <PetProfileTable {...props} />
    </ProtectedLayout>
  );
};

export const VaccinationPage = (props) => {
  return (
    <ProtectedLayout>
      <Vaccination {...props} />
    </ProtectedLayout>
  );
};

export const ShipmentPage = (props) => {
  return (
    <ProtectedLayout>
      <ShipmentRoute {...props} />
    </ProtectedLayout>
  );
};

export const PrayerProjectCategories = (props) => {
  return (
    <ProtectedLayout>
      <Category {...props} />
    </ProtectedLayout>
  );
};

export const AdminPage = (props) => {
  return (
    <ProtectedLayout>
      <AdminRoute {...props} />
    </ProtectedLayout>
  );
};

export const RolesPage = (props) => {
  return (
    <ProtectedLayout>
      <RoleRoute {...props} />
    </ProtectedLayout>
  );
};

export const ViewShipmentPage = (props) => {
  return (
    <ProtectedLayout>
      <ViewShipment {...props} />
    </ProtectedLayout>
  );
};

export const LoginPage = () => {
  return <Login />;
};

export const DownloadAudio = (props) => {
  return <DownloadAudiopAGE {...props} />;
};
