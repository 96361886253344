import React from "react";
import logo from "../../assets/images/logo.png";
import SelectBox from "../../components/form/select";
import Sizebox from "../../components/sizebox";
import { useHistory } from "react-router-dom";
import { userTypes } from "../../consts";

const AuthWrap = ({
  children,
  petImageBottom,
  onChange,
  name,
  value,
  handleSubmit,
  header = "Create An Account",
  subHeader = "Register as a Pet Owner, Vet Clinics or Kennels.",
  subBtnText = "SUBMIT & PAY",
  type = "create",
  showDrop = true,
}) => {
  const history = useHistory();

  return (
    <div className="authWrap-create-acc">
      <div className="header">
        <div className="logo">
          <img src={logo} loading="lazy" alt="NATIONAL PET DATABASE LOGO" />
        </div>
      </div>

      <div className="mainForm">
        <div className="header-text">
          <h2>{header}</h2>
          <p>{subHeader}</p>
        </div>

        <div className="petForm">
          <div className="petFormWrap">
            {showDrop ? (
              <SelectBox
                placeholder="Please select account type"
                options={[
                  {
                    label: "Pet Owner",
                    key: "petOwner",
                    value: userTypes.petOwner,
                  },
                  {
                    label: "Vet Clinic",
                    key: "VetClinic",
                    value: userTypes.vetClinic,
                  },
                  {
                    label: "Kennels",
                    key: "Kennels",
                    value: userTypes.kennels,
                  },
                ]}
                onChange={onChange}
                name={name}
                value={value}
              />
            ) : null}

            {children}

            <Sizebox height="30px" />

            <div className="submitform">
              <button onClick={handleSubmit}>{subBtnText}</button>
            </div>

            <Sizebox height="20px" />

            {type == "create" ? (
              <div className="petOwnerDesCon" style={{ marginBottom: "20px" }}>
                <p>
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      history.push("/");
                    }}
                    style={{ fontWeight: "600" }}
                  >
                    Sign In
                  </span>
                </p>
              </div>
            ) : null}

            {type == "login" ? (
              <div className="petOwnerDesCon" style={{ marginBottom: "20px" }}>
                <p>
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      history.push("/create_account");
                    }}
                    style={{ fontWeight: "600" }}
                  >
                    Sign Up
                  </span>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="petImageBottom">
        <img src={petImageBottom} alt="" />
      </div>
    </div>
  );
};

export default AuthWrap;
