import React from "react";
import "./index.scss";
import fileIcon from "../../assets/images/fileIcon.png";
const FileUploader = ({
  label,
  placeholder,
  onChange,
  name = "Select",
  value,
  type = "file",
}) => {
  return (
    <div className="appInputFileWrap">
      {label ? <label htmlFor="">{label}</label> : null}
      <div className="appInputFile">
        <input onChange={onChange} value={value} name={name} type={type} />
        <div className="appInputFileDes">
          <img src={fileIcon} alt="" />
          <h2>{value ? value : "Select a file to upload"}</h2>
          <p>max:10mb (png, jpg, docx, pdf)</p>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
