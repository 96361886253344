import React, { useState, useEffect } from "react";
import icons from "./icons";
import { Modal as ModalC } from "react-responsive-modal";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import { axiosCalls } from "../../_api";
import { hideLoader, showLoader } from "../../components/loader";
import { turnDate, turnUpperCase } from "../helper";
import { NotificationManager } from "react-notifications";
import "./index.css";

export default function BooksTable({
  onOpenModal,
  onOpenModalTracks,
  roles,
  deleteRole,
  reloadIsDelete,
}) {
  const [edit, setEdit] = useState({});
  const [roleId, setRoleId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteParam, setDeleteParam] = useState();
  const onOpenModalEdit = () => setOpenEdit(true);
  const onCloseModalEdit = () => setOpenEdit(false);

  const onOpenModalDelete = () => setOpenDelete(true);
  const onCloseModalDelete = () => setOpenDelete(false);

  const editRole = async (id) => {
    let payload = {
      name: edit.name,
      title: edit.title,
      description: edit.description,
    };
    const res = await axiosCalls(`categories/${id}`, "PUT", payload);
    if (res) {
      NotificationManager.success("Role edited successfully.", "Success");
    } else {
      NotificationManager.error(res.message, "Error");
    }
  };

  const handleEdit = (data) => {
    console.log(data, "dt");
    setEdit(data);
    onOpenModalEdit();
  };

  const handleDelete = (data) => {
    onOpenModalDelete();
    setDeleteParam(data);
  };

  const onChangeEdit = (e) => {
    setEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [50, 100, 150],
        }}
        columns={[
          {
            title: "Name",
            field: "name",
          },
          { title: "Description", field: "description" },
          {
            title: "Date added",
            field: "created_at",
            render: (rowData) => turnDate(rowData),
          },
          {
            title: "Actions",
            field: "action",
            render: (rowData) => (
              <div>
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "green",
                  }}
                  className="pending-action-table-btn"
                  onClick={() => handleEdit(rowData)}
                >
                  Edit Role
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "red",
                  }}
                  className="pending-action-table-btn"
                  onClick={() => handleDelete(rowData)}
                >
                  Delete Role
                </span>
              </div>
            ),
          },
        ]}
        data={roles}
        title="All Roles"
      />

      <ModalC open={openEdit} onClose={onCloseModalEdit} center>
        <div className="successModal">
          <h2>Edit Role</h2>

          <div className="mdInputWrap">
            <label htmlFor="">Name</label>
            <input
              type="text"
              name="name"
              value={edit.name}
              placeholder="Name"
              onChange={onChangeEdit}
            />
          </div>
          <div className="mdInputWrap">
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              value={edit.title}
              placeholder="Title"
              onChange={onChangeEdit}
            />
          </div>

          <div className="mdInputWrap">
            <label htmlFor="">Description</label>
            <input
              type="text"
              name="description"
              value={edit.description}
              placeholder="Description"
              onChange={onChangeEdit}
            />
          </div>

          <div className="sendRequestBtn">
            <button onClick={() => editRole(edit.id)}>Save</button>
          </div>
        </div>
      </ModalC>

      <ModalC open={openDelete} onClose={onCloseModalDelete} center>
        <div className="deleteHeader">Delete Role?</div>
        <div className="deleteSubText">
          Deleting this role is irreversible do you want to continue?
        </div>

        <div
          onClick={() => {
            deleteRole(deleteParam);
            onCloseModalDelete();
            reloadIsDelete();
          }}
          className="deleteYesBtn"
        >
          Yes
        </div>
        <div className="deleteNoBtn" onClick={onCloseModalDelete}>
          No
        </div>
      </ModalC>
    </div>
  );
}
