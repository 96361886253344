import React from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import { Visibility } from "@mui/icons-material";

const ResetPasswordForm = ({ handleChange, account, history, tab, setTab }) => {
  return (
    <div className="petOwner">
      <Sizebox height="20px" />
      {tab === 1 ? (
        <Input
          onChange={handleChange}
          name="email"
          value={account?.email}
          placeholder={"Email"}
          type="email"
        />
      ) : null}

      {tab === 2 ? (
        <>
          <Input
            onChange={handleChange}
            name="OTP"
            value={account?.OTP}
            placeholder={"OTP"}
            type="number"
          />
          <Sizebox height="20px" />

          <Input
            onChange={handleChange}
            name="password"
            value={account?.password}
            placeholder="New Password"
            type="password"
            IconRight={Visibility}
          />

          <Sizebox height="20px" />

          <Input
            onChange={handleChange}
            name="confirmPassword"
            value={account?.confirmPassword}
            placeholder="Confirm password"
            type="password"
            IconRight={Visibility}
          />
        </>
      ) : null}
    </div>
  );
};

export default ResetPasswordForm;
